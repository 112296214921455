import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ShippingAddressesFacade } from '../facades/shipping-addresses.facade';

export function shippingAddressesCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const shippingAddressesFacade = inject(ShippingAddressesFacade);
        shippingAddressesFacade.clear();
        return true;
    };
}
