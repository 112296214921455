import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ShippingAddress } from '../models/shipping-address.model';
import { ShippingAddressValue } from '../models/shipping-address.value';

export const shippingAddressesActions = createActionGroup({
    source: 'Shipping Addresses',
    events: {
        'Get Addresses': emptyProps(),
        'Get Addresses Success': props<{ items: ShippingAddress[]; current: ShippingAddress }>(),
        'Get Addresses Error': emptyProps(),

        'Set Address': props<{ value: ShippingAddressValue }>(),
        'Set Address Success': props<{ code: string }>(),
        'Set Address Error': emptyProps(),

        Clear: emptyProps(),
    },
});
