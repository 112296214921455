import { ShippingAddressValue } from './shipping-address.value';

class ShippingAddressValueDto {
    chosen_address_code: string;
    constructor(data: ShippingAddressValue) {
        this.chosen_address_code = data.code;
    }
}

export { ShippingAddressValueDto };
