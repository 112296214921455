import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of, catchError, finalize, map, repeat, switchMap, takeUntil } from 'rxjs';
import { HttpService } from '../services/http.service';
import { shippingAddressesActions } from '../actions/shipping-addresses.actions';
import { LoadersFacade } from '@app/store/loader';

@Injectable()
export class ShippingAddressesEffects {
    private loadersFacade = inject(LoadersFacade);
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    private stopConditions$ = merge(this.actions$.pipe(ofType(shippingAddressesActions.clear)));

    getShippingAddresses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.getAddresses),
            switchMap(() => {
                this.loadersFacade.add('get-shipping-addresses');
                return this.httpService.getAddresses().pipe(
                    map(([current, items]) => shippingAddressesActions.getAddressesSuccess({ current, items })),
                    catchError(() => of(shippingAddressesActions.getAddressesError())),
                    finalize(() => this.loadersFacade.delete('get-shipping-addresses')),
                );
            }),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    setShippingAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddress),
            switchMap((action) => {
                this.loadersFacade.add('set-shipping-addresses');
                return this.httpService.setAddress(action.value).pipe(
                    map(() => shippingAddressesActions.setAddressSuccess({ code: action.value.code })),
                    catchError(() => of(shippingAddressesActions.setAddressError())),
                    finalize(() => this.loadersFacade.delete('set-shipping-addresses')),
                );
            }),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });
}
