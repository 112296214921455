import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { ShippingAddressesFacade } from '../facades/shipping-addresses.facade';

export function shippingAddressesLoaded(): CanActivateFn {
    return () => {
        const shippingAddressesFacade = inject(ShippingAddressesFacade);
        return shippingAddressesFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    shippingAddressesFacade.getShippingAddresses();
                }
            }),
            filter((loaded): loaded is boolean => loaded !== null),
            take(1),
            map(() => true),
        );
    };
}
