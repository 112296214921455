import { ShippingAddressDto } from './shipping-address.model.dto';

export class ShippingAddress {
    customerNo: string;
    code: string;
    restaurantName: string;
    name: string;
    name2: string;
    city: string;
    county: string;
    countryRegionCode: string;
    postCode: string;
    address: string;
    address2: string;
    locationCode: string;
    email: string;
    streetCode: string;
    contact: string;
    shipmentMethodCode: string;
    logisticMinimumWithoutTax: number;

    constructor(shippingAddressDto: ShippingAddressDto) {
        this.customerNo = shippingAddressDto.customer_no;
        this.code = shippingAddressDto.code;
        this.restaurantName = shippingAddressDto.restaurant_name;
        this.name = shippingAddressDto.name;
        this.name2 = shippingAddressDto.name2;
        this.city = shippingAddressDto.city;
        this.county = shippingAddressDto.county;
        this.countryRegionCode = shippingAddressDto.country_region_code;
        this.postCode = shippingAddressDto.post_code;
        this.address = shippingAddressDto.address;
        this.address2 = shippingAddressDto.address2;
        this.locationCode = shippingAddressDto.location_code;
        this.email = shippingAddressDto.e_mail;
        this.streetCode = shippingAddressDto.street_code;
        this.contact = shippingAddressDto.contact;
        this.shipmentMethodCode = shippingAddressDto.shipment_method_code;
        this.logisticMinimumWithoutTax = Math.round(100 * (shippingAddressDto.logistic_minimum_lcy ?? 0));
    }
}
