import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { selectAddresses, selectCurrent, selectLoaded, selectCurrentCode } from '../selectors/shipping-addresses.selector';
import { shippingAddressesActions } from '../actions/shipping-addresses.actions';
import { ShippingAddress } from '../models';
import { ShippingAddressValue } from '../models/shipping-address.value';

@Injectable({
    providedIn: 'root',
})
export class ShippingAddressesFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    current$ = this.store.select(selectCurrent).pipe(filter((data): data is ShippingAddress => data !== null));
    addresses$ = this.store.select(selectAddresses).pipe(filter((data): data is ShippingAddress[] => data !== null));

    get currentCode() {
        let value: string | null = null;
        this.store
            .select(selectCurrentCode)
            .pipe(
                take(1),
                tap((current) => (value = current)),
            )
            .subscribe();
        return value;
    }

    getShippingAddresses(): void {
        this.store.dispatch(shippingAddressesActions.getAddresses());
    }

    setShippingAddresses(value: ShippingAddressValue): void {
        this.store.dispatch(shippingAddressesActions.setAddress({ value }));
    }

    clear(): void {
        this.store.dispatch(shippingAddressesActions.clear());
    }
}
