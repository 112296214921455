import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SHIPPING_ADDRESSES_FEATURE_KEY } from './keys';
import { shippingAddressesReducer } from './reducers/shipping-addresses.reducer';
import { ShippingAddressesEffects } from './effects/shipping-addresses.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(SHIPPING_ADDRESSES_FEATURE_KEY, shippingAddressesReducer),
        EffectsModule.forFeature(ShippingAddressesEffects),
    ],
})
export class ShippingAddressesStoreModule {}
